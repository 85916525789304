
// https://nft.scramble.media/rest/redeem
import axios, {AxiosError, AxiosResponse} from "axios";

const SCRAMBLE_REST = process.env.REACT_APP_SCRAMBLE_REST

export const postRedeem = async (address: String, message: String, signature: String) => {
    return axios({
        method: 'POST',
        url: `${SCRAMBLE_REST}/redeem`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            address: address,
            message: message,
            signature: signature
        }
    })
        .then((r: AxiosResponse) => {
            // HTTP 200 - signature good
            return r.status==200
        }).catch((err: AxiosError) => {
            console.log(err)
            return false
        })
}
