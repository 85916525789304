/*
  event AuctionBid(
        uint256 indexed auctionId,
        uint256 indexed tokenId,
        address indexed tokenContract,
        address sender,
        uint256 value,
        bool firstBid,
        bool extended
    );

    event AuctionEnded(
        uint256 indexed auctionId,
        uint256 indexed tokenId,
        address indexed tokenContract,
        address tokenOwner,
        address curator,
        address winner,
        uint256 amount,
        uint256 curatorFee,
        address auctionCurrency
    );

    event AuctionCreated(
            uint256 indexed auctionId,
            uint256 indexed tokenId,
            address indexed tokenContract,
            uint256 duration,
            uint256 reservePrice,
            address tokenOwner,
            address curator,
            uint8 curatorFeePercentage,
            address auctionCurrency
        );

    event AuctionDurationExtended(
            uint256 indexed auctionId,
            uint256 indexed tokenId,
            address indexed tokenContract,
            uint256 duration
        );
 */


import {BigNumber, ethers, utils} from "ethers";
import {AppDispatch} from "../redux/store";
import {globalMergeWallet, globalSetNftAuction} from "../redux/globalActions";
import {InfuraType} from "../redux/globalReducers";
import {providerGetLogsWithTimestamps} from "./infura";

function zoraAuctionBid(infura: InfuraType, auctionId: number) {
    let filter = infura.zoraAuctionContract.filters.AuctionBid(auctionId, null, null);

    let mkBid = (evt: ethers.utils.LogDescription, timestampSec: number) => {
        return {
            sender: evt.args.sender.toLowerCase(),
            amount: evt.args.value,
            timestamp: timestampSec * 1000 // ms
        }
    }

    return providerGetLogsWithTimestamps(infura.provider, {
        fromBlock: 0,
        toBlock: 'latest',
        address: infura.zoraAuctionContract.address,
        topics: filter.topics,
    })
        .then(logTimestamp =>
            logTimestamp.map(({log, timestamp}) => mkBid(infura.zoraAuctionInterface.parseLog(log), timestamp))
        );
}

function zoraAuctionEnded(infura: InfuraType, auctionId: number) {
    let filter = infura.zoraAuctionContract.filters.AuctionEnded(auctionId, null);

    return providerGetLogsWithTimestamps(infura.provider,{
        fromBlock: 0,
        toBlock: 'latest',
        address: infura.zoraAuctionContract.address,
        topics: filter.topics,
    })
        .then(logTimestamp => {
            if (logTimestamp.length > 0) {
                // TODO more than one ended event !?
                let raw = logTimestamp[0].log;
                let timestamp = logTimestamp[0].timestamp;
                let evt = infura.zoraAuctionInterface.parseLog(raw);
                return {
                    sender: evt.args.winner.toLowerCase(),
                    amount: evt.args.amount,
                    timestamp: timestamp * 1000 // ms
                }
            } else {
                return null;
            }
        });
}

function zoraAuctionCreated(infura: InfuraType, auctionId: number) {
    let filter = infura.zoraAuctionContract.filters.AuctionCreated(auctionId, null);

    return providerGetLogsWithTimestamps(infura.provider,{
        fromBlock: 0,
        toBlock: 'latest',
        address: infura.zoraAuctionContract.address,
        topics: filter.topics,
    })
        .then(logTimestamp => {
            if (logTimestamp.length > 0) {
                // TODO more than one started event !?
                let raw = logTimestamp[0].log;
                let timestamp = logTimestamp[0].timestamp;
                let evt = infura.zoraAuctionInterface.parseLog(raw);
                return {
                    duration: evt.args.duration,
                    reserve: evt.args.reservePrice,
                    timestamp: timestamp * 1000 // ms
                }
            } else {
                return null;
            }
        });
}

// returns new duration or null (seconds)
function zoraAuctionDurationExtended(infura: InfuraType, auctionId: number) {
    let filter = infura.zoraAuctionContract.filters.AuctionDurationExtended(auctionId, null);

    return infura.provider.getLogs({
        fromBlock: 0,
        toBlock: 'latest',
        address: infura.zoraAuctionContract.address,
        topics: filter.topics,
    })
        .then(events => {
            if (events.length > 0) {
                // take most recent only (sorted desc)
                let raw = events[events.length - 1];
                let evt = infura.zoraAuctionInterface.parseLog(raw);
                return evt.args.duration; // seconds
            } else {
                return null;
            }
        });
}

export const getNFTAuction = async (infura: InfuraType, tokenId: number, auctionId: number, dispatch: AppDispatch, clearWalletIsBusy: boolean = false) => {
    Promise.all([zoraAuctionBid(infura, auctionId), zoraAuctionEnded(infura, auctionId), zoraAuctionCreated(infura, auctionId), zoraAuctionDurationExtended(infura, auctionId)])
        .then(([aBids, aWinner, aCreated, aDurationExtended]) => {
            dispatch(globalSetNftAuction(
                tokenId,
                {
                    reserve: aCreated == null ? BigNumber.from(0) : aCreated.reserve,
                    duration: aDurationExtended != null ? aDurationExtended : aCreated != null ? aCreated.duration : 0,
                    bidHistory: aBids,
                    winner: aWinner
                }
            ))
            if (clearWalletIsBusy) dispatch(globalMergeWallet({isBusy: false}))
        })
        .catch(err => {
            if (clearWalletIsBusy) dispatch(globalMergeWallet({isBusy: false}))
            console.log(err)
        })
}
