import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import DropNFTQuickview from "./DropNFTQuickview";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import Swiper from "swiper";
import {getNFT} from "../contract/getNFT";
import {CHAIN_REFRESH_MS} from "../contract/infura";
import {markdownToReact, nftAltOrTokenUri} from "./common";
import {DropType, StateTypeNFT} from "../redux/globalReducers";

/*
 From index.html

 */

// init swiper after all NFT data in swiper DOM
const swiperInit = () => {
    return new Swiper('.home-slider-drop', {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        speed: 1500,
        parallax: true,
        autoplay: {
            delay: 5000,
        },
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
        },
        // Navigation arrows
        navigation: {
            nextEl: '#homeNext',
            prevEl: '#homePrev',
        }
    });
}

const mkSwiper = (dropId: String, drop: DropType, nftMap: { [p: string]: StateTypeNFT }) => {
    interface Slide {
        image: string
        text: string | null
        link: string | null
    }

    var slides: Array<Slide> = drop.splash
        ? [{image: drop.splash.image, text: drop.splash.text, link: null}]
        : [];
    drop.displayOrder.map(id => nftMap[id]).forEach(nft =>
        slides.push({
            image: nftAltOrTokenUri(nft) || "",
            text: nft.chainCore?.metadata.name || null,
            link: `/drop/${dropId}/nft/${nft.core.tokenId}`
        })
    )

    return <React.Fragment>
        {slides.map(slide => {
            return <div className="swiper-slide bg-cover"
                        style={{'backgroundImage': `url('${slide.image}')`}}>
                <div className="container-fluid px-lg-6 px-xl-7 h-100">
                    <div className="row h-100 justify-content-center align-items-center text-center"
                         data-swiper-parallax="-500">
                        <div className="col-lg-7">
                            {/*<p className="subtitle letter-spacing-3 mb-3 text-dark font-weight-light">Blue &amp; White</p>*/}
                            {slide.text && <h2 className="display-1 mb-3 text-white bg-dark opacity-8"
                                               style={{'lineHeight': 1}}>{slide.text}</h2>}
                            {slide.link && <Link to={slide.link}
                                                 className="btn btn-dark">DETAIL</Link>}
                        </div>
                    </div>
                </div>
            </div>
        })
        }
    </React.Fragment>
}

interface ParamTypes {
    dropId: string
}

export const Drop = ({dropId}: ParamTypes) => {

    const state = useAppSelector((state) => state)
    // TODO remove this hack to pick up state initial set
    const stateDrops = useAppSelector((state) => state.drops)

    const dispatch = useAppDispatch()
    useEffect(() => {
        let timer: NodeJS.Timer | null = null
        const f = () => {
            if (dropId != undefined && state.drops[dropId] != undefined && state.infura != undefined) {
                state.drops[dropId].displayOrder.forEach(d => {
                    const nft = state.nft[d]
                    getNFT(state.infura!, nft, dispatch)
                })
                timer = setTimeout(f, CHAIN_REFRESH_MS)
            }
        }
        f()
        return () => {
            if (timer != null) clearTimeout(timer)
        }
    }, [stateDrops, dropId]);

    const [prevDropId, setPrevDropId] = useState<string>("")
    const [swiperInstance, setSwiperInstance] = useState<Swiper | null>(null)
    useEffect(() => {
        if (prevDropId != dropId && swiperInstance != null) {
            swiperInstance.destroy(false, false)
            setSwiperInstance(null)
        }

        if (swiperInstance == null && dropId != undefined && state.drops[dropId] != undefined && state.drops[dropId].displayOrder.map(nftId => state.nft[nftId]).every(nft => nft.chainCore != null)) {
            setSwiperInstance(swiperInit())
            setPrevDropId(dropId)
        }
    }, [dropId, prevDropId, state, swiperInstance])

    if (dropId == undefined || state.drops[dropId] == undefined)
        return null;
    else if (state.drops[dropId].displayOrder.map(nftId => state.nft[nftId]).some(nft => nft.chainCore == null)) {
        return <div className="container-fluid h-100">
            <div className="row justify-content-center align-items-center text-center">
                <div className="col-lg-6">
                    <div style={{height: '45vh'}}></div>
                    <div className="spinner-border text-primary" role="status" style={{width: '3rem', height: '3rem'}}>
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div style={{height: '40vh'}}></div>
                </div>
            </div>
        </div>
    } else {

        return (
            <React.Fragment>
                { /* Slider main container */}
                <div className="swiper-container home-slider-drop" style={{height: '95vh', 'minHeight': '600px'}}>
                    { /* Additional required wrapper */}
                    <div className="swiper-wrapper">
                        { /* Slides */}
                        {mkSwiper(dropId, state.drops[dropId], state.nft)}
                        {/*{*/}
                        {/*    state.drops[dropId].displayOrder.map(nftId => state.nft[nftId]).map(nft => {*/}
                        {/*        return <div className="swiper-slide bg-cover"*/}
                        {/*                    style={{'backgroundImage': `url('${nftAltOrTokenUri(nft)}')`}}>*/}
                        {/*            <div className="container-fluid px-lg-6 px-xl-7 h-100">*/}
                        {/*                <div className="row h-100 justify-content-center align-items-center text-center"*/}
                        {/*                     data-swiper-parallax="-500">*/}
                        {/*                    <div className="col-lg-6">*/}
                        {/*                        /!*<p className="subtitle letter-spacing-3 mb-3 text-dark font-weight-light">Blue &amp; White</p>*!/*/}
                        {/*                        <h2 className="display-1 mb-3 text-gray-300"*/}
                        {/*                            style={{'lineHeight': 1}}>{nft.chainCore?.metadata.name}</h2>*/}
                        {/*                        <Link to={`/drop/${dropId}/nft/${nft.core.tokenId}`}*/}
                        {/*                              className="btn btn-dark">DETAIL</Link>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    })*/}
                        {/*}*/}

                        {/*<div className="swiper-slide bg-cover bg-cover-right"*/}
                        {/*     style={{'backgroundImage': "url('/img/photo/home-1-plain.jpg')"}}>*/}
                        {/*    <div className="container-fluid px-lg-6 px-xl-7 h-100">*/}
                        {/*        <div className="row h-100 align-items-center" data-swiper-parallax="-500">*/}
                        {/*            <div className="col-lg-6">*/}
                        {/*                <p className="subtitle letter-spacing-3 mb-3 text-dark font-weight-light">Our*/}
                        {/*                    all-time*/}
                        {/*                    favourites</p>*/}
                        {/*                <h2 className="display-1 mb-3" style={{'lineHeight': 1}}>Blouses &amp; Tops</h2>*/}
                        {/*                <p className="text-muted mb-5">The bedding was hardly able to cover it and*/}
                        {/*                    seemed ready*/}
                        {/*                    to slide off any moment. His many legs, pit</p><a className="btn btn-dark"*/}
                        {/*                                                                      href="#">Discover*/}
                        {/*                more</a>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="swiper-slide bg-cover"*/}
                        {/*     style={{'backgroundImage': "url('/img/photo/home-2-plain.jpg')"}}>*/}
                        {/*    <div className="container-fluid px-lg-6 px-xl-7 h-100">*/}
                        {/*        <div className="row h-100 justify-content-center align-items-center text-center"*/}
                        {/*             data-swiper-parallax="-500">*/}
                        {/*            <div className="col-lg-6">*/}
                        {/*                <p className="subtitle letter-spacing-3 mb-3 text-dark font-weight-light">Blue &amp; White</p>*/}
                        {/*                <h2 className="display-1 mb-3" style={{'lineHeight': 1}}>Linen and denim</h2><a*/}
                        {/*                className="btn btn-dark" href="#">Start shopping</a>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="swiper-slide bg-cover"*/}
                        {/*     style={{'backgroundImage': "url('/img/photo/home-3-plain.jpg')"}}>*/}
                        {/*    <div className="container-fluid px-lg-6 px-xl-7 h-100">*/}
                        {/*        <div className="row h-100 align-items-center" data-swiper-parallax="-500">*/}
                        {/*            <div className="col-lg-6 offset-lg-6">*/}
                        {/*                <p className="subtitle letter-spacing-3 mb-3 text-primary font-weight-light mb-4">Sneakers</p>*/}
                        {/*                <h2 className="display-1 mb-5" style={{'lineHeight': 1}}>For every*/}
                        {/*                    occassion</h2><a*/}
                        {/*                className="btn btn-dark" href="#">Start shopping</a>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-nav d-none d-lg-block">
                        <div className="swiper-button-prev" id="homePrev"></div>
                        <div className="swiper-button-next" id="homeNext"></div>
                    </div>
                </div>

                <div className="container-fluid container-fluid-px py-6">
                    {state.drops[dropId].about != null &&
                      <div className="row">
                        <div className="col-lg-10 col-xl-8 text-center mx-auto">
                          <h2 className="display-3 mb-5">{state.drops[dropId].about?.heading}</h2>
                            {state.drops[dropId].about?.body.map((p, pIdx) =>
                                <p className={"lead text-muted " + (pIdx + 1 == state.drops[dropId].about?.body.length ? "mb-6" : "mb-4")}>
                                    {p.map((l, lIdx) => <React.Fragment>{markdownToReact(l)}{lIdx != p.length - 1 &&
                                      <br/>}</React.Fragment>)}
                                </p>
                            )}
                        </div>
                      </div>
                    }

                    <div className="row">
                        { /* nfts  */}
                        {
                            state.drops[dropId].displayOrder.map((tokenId: string) => {
                                return (<DropNFTQuickview key={`quick-${tokenId}`} dropId={dropId}
                                                          nft={state.nft[tokenId]}/>)
                            })
                        }
                    </div>
                    { /* Quickview Modal     */}
                    <div className="modal fade quickview" id="quickView" tabIndex={-1} role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <button className="close close-absolute close-rotate" type="button" data-dismiss="modal"
                                        aria-label="Close">
                                    <svg className="svg-icon w-3rem h-3rem svg-icon-light align-middle">
                                        <use xlinkHref="#close-1"></use>
                                    </svg>
                                </button>
                                <div className="modal-body quickview-body">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="detail-carousel">
                                                <div className="product-badge badge badge-primary">Freshx</div>
                                                <div className="product-badge badge badge-dark">Sale</div>
                                                <div className="swiper-container quickview-slider" id="quickViewSlider">
                                                    { /* Additional required wrapper */}
                                                    <div className="swiper-wrapper">
                                                        { /* Slides */}
                                                        <div className="swiper-slide"><img className="img-fluid"
                                                                                           src="/img/product/detail-1-gray.jpg"
                                                                                           alt="Modern Jacket 1"/></div>
                                                        <div className="swiper-slide"><img className="img-fluid"
                                                                                           src="/img/product/detail-2-gray.jpg"
                                                                                           alt="Modern Jacket 2"/></div>
                                                        <div className="swiper-slide"><img className="img-fluid"
                                                                                           src="/img/product/detail-3-gray.jpg"
                                                                                           alt="Modern Jacket 3"/></div>
                                                        <div className="swiper-slide"><img className="img-fluid"
                                                                                           src="/img/product/detail-4-gray.jpg"
                                                                                           alt="Modern Jacket 4"/></div>
                                                        <div className="swiper-slide"><img className="img-fluid"
                                                                                           src="/img/product/detail-5-gray.jpg"
                                                                                           alt="Modern Jacket 5"/></div>
                                                    </div>
                                                </div>
                                                <div className="swiper-thumbs" data-swiper="#quickViewSlider">
                                                    <button className="swiper-thumb-item detail-thumb-item mb-3 active">
                                                        <img
                                                            className="img-fluid" src="/img/product/detail-1-gray.jpg"
                                                            alt="Modern Jacket 0"/></button>
                                                    <button className="swiper-thumb-item detail-thumb-item mb-3"><img
                                                        className="img-fluid" src="/img/product/detail-2-gray.jpg"
                                                        alt="Modern Jacket 1"/></button>
                                                    <button className="swiper-thumb-item detail-thumb-item mb-3"><img
                                                        className="img-fluid" src="/img/product/detail-3-gray.jpg"
                                                        alt="Modern Jacket 2"/></button>
                                                    <button className="swiper-thumb-item detail-thumb-item mb-3"><img
                                                        className="img-fluid" src="/img/product/detail-4-gray.jpg"
                                                        alt="Modern Jacket 3"/></button>
                                                    <button className="swiper-thumb-item detail-thumb-item mb-3"><img
                                                        className="img-fluid" src="/img/product/detail-5-gray.jpg"
                                                        alt="Modern Jacket 4"/></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 p-lg-5">
                                            <h2 className="mb-4 mt-4 mt-lg-1">Push-up Jeans</h2>
                                            <div
                                                className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between mb-4">
                                                <ul className="list-inline mb-2 mb-sm-0">
                                                    <li className="list-inline-item h4 font-weight-light mb-0">$65.00</li>
                                                    <li className="list-inline-item text-muted font-weight-light">
                                                        <del>$90.00</del>
                                                    </li>
                                                </ul>
                                                <div className="d-flex align-items-center text-sm">
                                                    <ul className="list-inline mr-2 mb-0">
                                                        <li className="list-inline-item mr-0"><i
                                                            className="fa fa-star text-primary"></i></li>
                                                        <li className="list-inline-item mr-0"><i
                                                            className="fa fa-star text-primary"></i></li>
                                                        <li className="list-inline-item mr-0"><i
                                                            className="fa fa-star text-primary"></i></li>
                                                        <li className="list-inline-item mr-0"><i
                                                            className="fa fa-star text-primary"></i></li>
                                                        <li className="list-inline-item mr-0"><i
                                                            className="fa fa-star text-gray-300"></i></li>
                                                    </ul>
                                                    <span className="text-muted text-uppercase">25 reviews</span>
                                                </div>
                                            </div>
                                            <p className="mb-4 text-muted">Samsa was a travelling salesman - and above
                                                it
                                                there
                                                hung a picture that he had recently cut out of an illustrated magazine
                                                and
                                                housed in a nice, gilded frame.</p>
                                            <form id="buyForm_modal" action="#">
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-12 detail-option mb-4">
                                                        <h6 className="detail-option-heading">Size <span>(required)</span>
                                                        </h6>
                                                        <select className="selectpicker" name="size"
                                                                data-style="btn-selectpicker">
                                                            <option value="value_0">Small</option>
                                                            <option value="value_1">Medium</option>
                                                            <option value="value_2">Large</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-12 detail-option mb-5">
                                                        <h6 className="detail-option-heading">Type <span>(required)</span>
                                                        </h6>
                                                        <label
                                                            className="btn btn-sm btn-outline-primary detail-option-btn-label"
                                                            htmlFor="material_0_modal"> Hoodie
                                                            <input className="input-invisible" type="radio"
                                                                   name="material"
                                                                   value="value_0" id="material_0_modal" required/>
                                                        </label>
                                                        <label
                                                            className="btn btn-sm btn-outline-primary detail-option-btn-label"
                                                            htmlFor="material_1_modal"> College
                                                            <input className="input-invisible" type="radio"
                                                                   name="material"
                                                                   value="value_1" id="material_1_modal" required/>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="input-group w-100 mb-4">
                                                    <input className="form-control form-control-lg detail-quantity"
                                                           name="items"
                                                           type="number" value="1"/>
                                                    <div className="input-group-append flex-grow-1">
                                                        <button className="btn btn-dark btn-block" type="submit"><i
                                                            className="fa fa-shopping-cart mr-2"></i>Add to Cart
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-6"><a href="#"> <i
                                                        className="far fa-heart mr-2"></i>Add to
                                                        wishlist </a></div>
                                                    <div className="col-6 text-right">
                                                        <ul className="list-inline mb-0">
                                                            <li className="list-inline-item mr-2"><a
                                                                className="text-dark text-hover-primary" href="#"><i
                                                                className="fab fa-facebook-f"> </i></a></li>
                                                            <li className="list-inline-item"><a
                                                                className="text-dark text-hover-primary" href="#"><i
                                                                className="fab fa-twitter"> </i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <ul className="list-unstyled">
                                                    <li><strong>Category:</strong> <a className="text-muted"
                                                                                      href="#">Jeans</a>
                                                    </li>
                                                    <li><strong>Tags:</strong> <a className="text-muted"
                                                                                  href="#">Leisure</a>, <a
                                                        className="text-muted" href="#">Elegant</a></li>
                                                </ul>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}
