import React from "react";
import {StateType} from "../redux/globalReducers";
import {formatUnixMs, formatWei} from "./common";
import {ethers} from "ethers";

/**
 * Bid history in reverse chronological order
 *
 * @param nft
 * @constructor
 */
export const BidHistory = ({nft}: StateType["nft"]) => {

    const appendBorder0 = (s: string, i: number) => s + (i==0 ? " border-0" : "")
    return (
        <div className="row">
            { nft.chainAuction?.bidHistory.slice().reverse().map( (b: any, idx: number) => {
                return <div key={`bidhistory-${idx}`} className="col-12">
                    <table className="table text-sm">
                        <tbody>
                        <tr>
                            <th className={appendBorder0("font-weight-normal border-0", idx)}>{b.sender}</th>
                            <td className={appendBorder0("text-muted border-0", idx)}>{ethers.constants.EtherSymbol}{formatWei(b.amount)}</td>
                            <td className={appendBorder0("text-muted border-0", idx)}>{formatUnixMs(b.timestamp)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            })}
        </div>
    )
}
