import {
    GLOBAL_MERGE_WALLET, GLOBAL_REDEEM_INITIAL, GLOBAL_REDEEM_SET,
    GLOBAL_SET_BIDS,
    GLOBAL_SETNFT_AUCTION, GLOBAL_SETNFT_CORE, GLOBAL_SETNFT_INITIAL, GLOBAL_SETNFT_SALE
} from "./constants";
import {RedeemType, StateType, StateTypeNFT} from "./globalReducers";

// -- initial NFT
export const globalSetNftInitial = (nft: StateType["nft"], drops: StateType["drops"], menu: StateType["menu"]) => {
    return {
        type: GLOBAL_SETNFT_INITIAL,
        nft: nft,
        drops: drops,
        menu: menu
    }
}

// -- update NFT
export const globalSetNftCore = (tokenId: number, chain: StateTypeNFT["chainCore"]) => {
    return {
        type: GLOBAL_SETNFT_CORE,
        tokenId: tokenId,
        chain: chain
    }
}
export const globalSetNftSale = (tokenId: number, chain: StateTypeNFT["chainSale"]) => {
    return {
        type: GLOBAL_SETNFT_SALE,
        tokenId: tokenId,
        chain: chain
    }
}
export const globalSetNftAuction = (tokenId: number, chain: StateTypeNFT["chainAuction"]) => {
    return {
        type: GLOBAL_SETNFT_AUCTION,
        tokenId: tokenId,
        chain: chain
    }
}

/**
 * Forces lower case wallet.account address
 * @param wallet
 */
export const globalMergeWallet = (wallet: any) => {
    if(wallet!=null && wallet.hasOwnProperty('account') && wallet.account!=null) {
        wallet.account = wallet.account.toString().toLowerCase()
    }
    return {
        type: GLOBAL_MERGE_WALLET,
        wallet: wallet
    }
}

export const globalRedeemInit = () => {
    return {
        type: GLOBAL_REDEEM_INITIAL
    }
}

export const globalRedeemSet = (redeem: RedeemType) => {
    return {
        type: GLOBAL_REDEEM_SET,
        redeem: redeem
    }
}
