import {ethers} from "ethers";
import abiMedia from "../contract/zoraMedia.json";
import abiMarket from "../contract/zoraMarket.json";
import abiAuction from "../contract/zoraAuction.json";
import abiScrambleNFTBuy from "../contract/scrambleNFTBuy.json";

const addressMainZoraMedia = process.env.REACT_APP_SCRAMBLE_ZORAMEDIA!
const addressMainZoraMarket = process.env.REACT_APP_SCRAMBLE_ZORAMARKET!
const addressMainZoraAuction = process.env.REACT_APP_SCRAMBLE_ZORAAUCTION!
const addressScrambleNFTBuy = process.env.REACT_APP_SCRAMBLE_NFTBUY!
const mainNetwork = process.env.REACT_APP_SCRAMBLE_NETWORK!

const INFURA_APIKEY = process.env.REACT_APP_INFURA_APIKEY

export const CHAIN_REFRESH_MS = 30000 // 30 sec
export const etherscanZoraAuth = (tokenId: number) => `https://${process.env.REACT_APP_SCRAMBLE_ETHERSCAN}/token/${addressMainZoraMedia}?a=${tokenId}`
// opensea listing link
// https://opensea.io/assets/ethereum/0xabefbc9fd2f806065b4f3c237d4b59d9a97bcac7/4229
export const openseaListing = (tokenId: number) => `https://opensea.io/assets/ethereum/${addressMainZoraMedia}/${tokenId}`

export function initInfura() {
    let infura = new ethers.providers.InfuraProvider(mainNetwork, INFURA_APIKEY);

    return {
        provider: infura,
        zoraMarketContract: new ethers.Contract(addressMainZoraMarket, abiMarket.abi, infura),
        zoraMarketInterface: new ethers.utils.Interface(abiMarket.abi),
        zoraMediaContract: new ethers.Contract(addressMainZoraMedia, abiMedia.abi, infura),
        zoraMediaInterface: new ethers.utils.Interface(abiMedia.abi),
        zoraAuctionContract: new ethers.Contract(addressMainZoraAuction, abiAuction.abi, infura),
        zoraAuctionInterface: new ethers.utils.Interface(abiAuction.abi),
        scrambleNFTBuyContract: new ethers.Contract(addressScrambleNFTBuy, abiScrambleNFTBuy.abi, infura),
        scrambleNFTBuyInterface: new ethers.utils.Interface(abiScrambleNFTBuy.abi)
    }
}

export function providerGetLogsWithTimestamps(provider: ethers.providers.Provider, filter: ethers.providers.Filter) {
    return provider.getLogs(filter)
        .then(logs =>
            Promise.all(
                logs.map(log =>
                    provider.getBlock(log.blockNumber).then(block =>
                        ({log: log, timestamp: block.timestamp}))
                )
            )
        )
}
