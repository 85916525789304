import {StateTypeNFT} from "./globalReducers";

export const nftIsForSale = (nft: StateTypeNFT) => {
    return nft.chainSale != null &&
      nft.chainSale.ask != null &&
      nft.chainSale.winner == null
}

export const nftIsSold = (nft: StateTypeNFT) => {
    return nft.chainSale != null &&
      nft.chainSale.ask == null &&
      nft.chainSale.winner != null
}
