import {useAppDispatch} from "../redux/hooks";
import {useEffect} from "react";
import {getInitialState} from "./getInitialState";
import { useHistory } from "react-router-dom";

/***
 * Redirect / to last drop in drop menu (presumed feature drop).
 *
 * @constructor
 */
export const RedirectFeatureDrop = () => {
    const dispatch = useAppDispatch()
    const history = useHistory();
    useEffect(() => {
        getInitialState(dispatch)
            .then(initState => {
                if(initState.menu.length>0) {
                    const dropId = initState.menu[initState.menu.length - 1];
                    history.push(`/drop/${dropId}`, null); // push browser url
                }
            })
            .catch(_ => {})
    }, []);

    return (<div/>);
}
