// import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/stylesheet.css';
import './scss/style.default.scss';

import * as $ from 'jquery';

// Swiper
// AOS - AnimationOnScroll
import 'aos/dist/aos.css';
// Custom Scrollbar
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';

import Popper from 'popper.js';
import 'bootstrap';

// Swiper Carousel
import 'swiper/css/swiper.min.css';

// Bootstrap Select
import 'bootstrap-select';

// AOS - AnimationOnScroll
import 'aos';

// Custom Scrollbar
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';

import './js/custom-scrollbar-init.js';

// Smooth scroll
// Object Fit Images - Fallback for browsers that don't support object-fit
import 'object-fit-images';

// JavaScript Countdown
// import './js/countdown.js';

// Main Theme files
import './js/sliders-init.js';
import './js/photoswipe-init.js';
import './js/theme.js';

import React from 'react';
import ReactDOM from 'react-dom';
import {connect, Provider} from "react-redux";

import reportWebVitals from './reportWebVitals';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Page} from "./drop/Page";
import {Drop} from "./drop/Drop";
import {Detail} from "./drop/Detail";

import store from "./redux/store";
import ScrollToTop from "./ScrollToTop";
import {RedirectFeatureDrop} from "./drop/RedirectFeatureDrop";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <ScrollToTop/>
                <Switch>
                    <Route path="/drop/:dropId/nft/:tokenId" render={(props) => (
                        <Page showHomeLink={true} dropId={props.match.params.dropId}><Detail/></Page>
                    )}/>
                    <Route path="/drop/:dropId" render={(props) => (
                        <Page showHomeLink={false} dropId={props.match.params.dropId}><Drop dropId={props.match.params.dropId}/></Page>
                    )}/>
                    <Route path="/" render={_ => <RedirectFeatureDrop/> }/>
                </Switch>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
