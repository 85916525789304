import {InfuraType} from "../redux/globalReducers";
import {AppDispatch} from "../redux/store";
import {globalMergeWallet, globalSetNftSale} from "../redux/globalActions";

function zoraMarketCurrentAsk(infura: InfuraType, tokenId: number) {
    // { amount: 0, currency: "0x0abc" } assume currency is wETH
    // { amount: 0, currency: "0x0" } when no ask
    return infura.zoraMarketContract.currentAskForToken(tokenId)
        .then((ask: any) => ask.amount.isZero()?null:ask.amount);
}

// emit BidFinalized(tokenId, bid);
// struct Bid {
//         // Amount of the currency being bid
//         uint256 amount;
//         // Address to the ERC20 token being used to bid
//         address currency;
//         // Address of the bidder
//         address bidder;
//         // Address of the recipient
//         address recipient;
//         // % of the next sale to award the current owner
//         Decimal.D256 sellOnShare;
//     }

function zoraMarketBidFinalized(infura: InfuraType, tokenId: number) {
    let filter = infura.zoraMarketContract.filters.BidFinalized(tokenId, null);

    return infura.provider.getLogs({
        fromBlock: 0,
        toBlock: 'latest',
        address: infura.zoraMarketContract.address,
        topics: filter.topics,
    })
        .then(events => {
            if (events.length === 0) return null
            else {
                // take first BidFinalized as "our" site sale
                let raw = events[0];
                let evt = infura.zoraMarketInterface.parseLog(raw);
                return {
                    recipient: evt.args.bid.recipient.toLowerCase(),
                    amount: evt.args.bid.amount
                }
            }
        });
}

export const getNFTSale = async (infura: InfuraType, tokenId: number, dispatch: AppDispatch, clearWalletIsBusy: boolean = false) => {

    Promise.all([zoraMarketCurrentAsk(infura, tokenId), zoraMarketBidFinalized(infura, tokenId)])
        .then(([ask, winner]) => {
            dispatch(globalSetNftSale(
                tokenId,
                {ask: ask, winner: winner}
            ))
            if (clearWalletIsBusy) dispatch(globalMergeWallet({isBusy: false}))

        })
        .catch(err => console.log(err))
}
