import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {BuyForm} from "./BuyForm";
import {BidHistory} from "./BidHistory";
import {ethers} from "ethers";
import {getNFT} from "../contract/getNFT";
import {canRedeem, formatWei, markdownToReact, nftAltOrTokenUri} from "./common";
import Swiper from "swiper";
import * as $ from "jquery";
import {CHAIN_REFRESH_MS, etherscanZoraAuth, openseaListing} from "../contract/infura";
import {nftIsForSale, nftIsSold} from "../redux/nft";
import {StateTypeNFT} from "../redux/globalReducers";
import {RedeemForm} from "./RedeemForm";

const swiperInit = () => {
    return new Swiper('.detail-slider-nft', {
        direction: 'horizontal',
        loop: true,
        on: {
           // slideChangeTransitionStart: highlightThumb
        }
    });

    // $('#quickView').on('shown.bs.modal', function (e) {
    //     quickViewSlider = new Swiper('#quickViewSlider', {
    //         mode: 'horizontal',
    //         loop: true,
    //         on: {
    //             slideChangeTransitionStart: highlightThumb
    //         }
    //     });
    // })
    //
    // $('#quickView').on('hidden.bs.modal', function (e) {
    //     quickViewSlider.destroy();
    // })

}

const multiLineDescription = (nft: StateTypeNFT) => nft?.chainCore?.metadata.description.split("\n")

interface ParamTypes {
    dropId: string
    tokenId: string
}

export const Detail = () => {
    const now = new Date()

    // from url
    let {dropId, tokenId} = useParams<ParamTypes>();
    const nft = useAppSelector((state) => state.nft.hasOwnProperty(tokenId) ? state.nft[tokenId] : null)
    const stateWallet = useAppSelector((state) => state.wallet)
    const stateInfura = useAppSelector((state) => state.infura)
    const dispatch = useAppDispatch()
    // TODO remove this hack to pick up state initial set
    const stateDrops = useAppSelector((state) => state.drops)

    useEffect(() => {
        let timer: NodeJS.Timer | null = null
        const f = () => {
            if (nft != null && stateInfura != null) getNFT(stateInfura, nft, dispatch)
            timer = setTimeout(f, CHAIN_REFRESH_MS)
        }
        f()
        return () => {
            if(timer!=null) clearTimeout(timer)
        }
    }, [stateDrops]);

    const [didSwiperInit, setDidSwiperInit] = useState(false)
    useEffect(() => {
        if(didSwiperInit==false && nft!=null && nft.chainSale!=null) {
            swiperInit()
            setDidSwiperInit(true)
        }
    }, [nft])

    if(nft==null)
        return null
    else
        return (
        <React.Fragment>
            <section>
                <div className="container pt-5">
                    {/*<ul className="breadcrumb undefined">*/}
                    {/*    <li className="breadcrumb-item"><a href="index.html">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item"><a href="category-full.html">Tops and Jackets</a></li>*/}
                    {/*    <li className="breadcrumb-item active">Modern Jacket</li>*/}
                    {/*</ul>*/}
                    {/*<Link to={`/drop/${dropId}`}>Drop</Link>*/}
                    <div className="row">
                        <div className="col-lg-7 order-2 order-lg-1">
                            <div className="detail-carousel">
                                {nftIsForSale(nft) && <div className="product-badge badge badge-primary">BUY NOW</div>}
                                {nftIsSold(nft) && <div className="product-badge badge badge-primary">SOLD</div>}
                                <div className="swiper-container detail-slider-nft photoswipe-gallery" id="detailSlider">
                                    { /* Additional required wrapper */}
                                    <div className="swiper-wrapper">
                                        { /* Slides */}
                                        <div className="swiper-slide"><a className="btn btn-photoswipe"
                                                                         href={nftAltOrTokenUri(nft)}
                                                                         data-caption={nft.chainCore?.metadata.name}
                                                                         data-toggle="photoswipe" data-width="1200"
                                                                         data-height="1200">
                                            <svg className="svg-icon svg-icon-heavy">
                                                <use xlinkHref="#expand-1"></use>
                                            </svg>
                                        </a>
                                            <div data-toggle="zoom" data-image={nftAltOrTokenUri(nft)}>
                                                {!nft.chainCore?.metadata.mimeType.startsWith("video") && <img className="img-fluid" src={nft.chainCore?.tokenURI} alt=""/>}
                                                {nft.chainCore?.metadata.mimeType.startsWith("video") && <video controls className="img-fluid" width="1024px" height="1024px" preload="auto"><source src={`${nft.chainCore?.tokenURI}#t=0.1`}/></video>}
                                            </div>
                                        </div>
                                        {/*<div className="swiper-slide"><a className="btn btn-photoswipe"*/}
                                        {/*                                 href="/img/product/detail-1-gray.jpg"*/}
                                        {/*                                 data-caption="Push-up Jeans 2 - Caption text"*/}
                                        {/*                                 data-toggle="photoswipe" data-width="1200"*/}
                                        {/*                                 data-height="1200">*/}
                                        {/*    <svg className="svg-icon svg-icon-heavy">*/}
                                        {/*        <use xlinkHref="#expand-1"></use>*/}
                                        {/*    </svg>*/}
                                        {/*</a>*/}
                                        {/*    <div data-toggle="zoom" data-image="/img/product/detail-1-gray.jpg"><img*/}
                                        {/*        className="img-fluid" src="/img/product/detail-1-gray.jpg"*/}
                                        {/*        alt="Push-up Jeans 2"/></div>*/}
                                        {/*</div>*/}
                                        {/*<div className="swiper-slide"><a className="btn btn-photoswipe"*/}
                                        {/*                                 href="/img/product/detail-2-gray.jpg"*/}
                                        {/*                                 data-caption="Push-up Jeans 3 - Caption text"*/}
                                        {/*                                 data-toggle="photoswipe" data-width="1200"*/}
                                        {/*                                 data-height="1200">*/}
                                        {/*    <svg className="svg-icon svg-icon-heavy">*/}
                                        {/*        <use xlinkHref="#expand-1"></use>*/}
                                        {/*    </svg>*/}
                                        {/*</a>*/}
                                        {/*    <div data-toggle="zoom" data-image="/img/product/detail-2-gray.jpg"><img*/}
                                        {/*        className="img-fluid" src="/img/product/detail-2-gray.jpg"*/}
                                        {/*        alt="Push-up Jeans 3"/></div>*/}
                                        {/*</div>*/}
                                        {/*<div className="swiper-slide"><a className="btn btn-photoswipe"*/}
                                        {/*                                 href="/img/product/detail-4-gray.jpg"*/}
                                        {/*                                 data-caption="Push-up Jeans 4 - Caption text"*/}
                                        {/*                                 data-toggle="photoswipe" data-width="1200"*/}
                                        {/*                                 data-height="1200">*/}
                                        {/*    <svg className="svg-icon svg-icon-heavy">*/}
                                        {/*        <use xlinkHref="#expand-1"></use>*/}
                                        {/*    </svg>*/}
                                        {/*</a>*/}
                                        {/*    <div data-toggle="zoom" data-image="/img/product/detail-4-gray.jpg"><img*/}
                                        {/*        className="img-fluid" src="/img/product/detail-4-gray.jpg"*/}
                                        {/*        alt="Push-up Jeans 4"/></div>*/}
                                        {/*</div>*/}
                                        {/*<div className="swiper-slide"><a className="btn btn-photoswipe"*/}
                                        {/*                                 href="/img/product/detail-5-gray.jpg"*/}
                                        {/*                                 data-caption="Push-up Jeans 5 - Caption text"*/}
                                        {/*                                 data-toggle="photoswipe" data-width="1200"*/}
                                        {/*                                 data-height="1200">*/}
                                        {/*    <svg className="svg-icon svg-icon-heavy">*/}
                                        {/*        <use xlinkHref="#expand-1"></use>*/}
                                        {/*    </svg>*/}
                                        {/*</a>*/}
                                        {/*    <div data-toggle="zoom" data-image="/img/product/detail-5-gray.jpg"><img*/}
                                        {/*        className="img-fluid" src="/img/product/detail-5-gray.jpg"*/}
                                        {/*        alt="Push-up Jeans 5"/></div>*/}
                                        {/*</div>*/}
                                        {/*<div className="swiper-slide"><a className="btn btn-photoswipe"*/}
                                        {/*                                 href="/img/product/detail-6.jpg"*/}
                                        {/*                                 data-caption="Push-up Jeans 6 - Caption text"*/}
                                        {/*                                 data-toggle="photoswipe" data-width="1200"*/}
                                        {/*                                 data-height="1200">*/}
                                        {/*    <svg className="svg-icon svg-icon-heavy">*/}
                                        {/*        <use xlinkHref="#expand-1"></use>*/}
                                        {/*    </svg>*/}
                                        {/*</a>*/}
                                        {/*    <div data-toggle="zoom" data-image="/img/product/detail-6.jpg"><img*/}
                                        {/*        className="img-fluid" src="/img/product/detail-6.jpg"*/}
                                        {/*        alt="Push-up Jeans 6"/></div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-thumbs" data-swiper="#detailSlider">
                                <button className="swiper-thumb-item detail-thumb-item mb-3 active"><img
                                    className="img-fluid" src={nftAltOrTokenUri(nft)} alt={nft.chainCore?.metadata.name}/>
                                </button>
                                {/*<button className="swiper-thumb-item detail-thumb-item mb-3"><img className="img-fluid"*/}
                                {/*                                                                  src="/img/product/detail-1-gray.jpg"*/}
                                {/*                                                                  alt="Push-up Jeans 1"/>*/}
                                {/*</button>*/}
                                {/*<button className="swiper-thumb-item detail-thumb-item mb-3"><img className="img-fluid"*/}
                                {/*                                                                  src="/img/product/detail-2-gray.jpg"*/}
                                {/*                                                                  alt="Push-up Jeans 2"/>*/}
                                {/*</button>*/}
                                {/*<button className="swiper-thumb-item detail-thumb-item mb-3"><img className="img-fluid"*/}
                                {/*                                                                  src="/img/product/detail-4-gray.jpg"*/}
                                {/*                                                                  alt="Push-up Jeans 3"/>*/}
                                {/*</button>*/}
                                {/*<button className="swiper-thumb-item detail-thumb-item mb-3"><img className="img-fluid"*/}
                                {/*                                                                  src="/img/product/detail-5-gray.jpg"*/}
                                {/*                                                                  alt="Push-up Jeans 4"/>*/}
                                {/*</button>*/}
                                {/*<button className="swiper-thumb-item detail-thumb-item mb-3"><img className="img-fluid"*/}
                                {/*                                                                  src="/img/product/detail-6.jpg"*/}
                                {/*                                                                  alt="Push-up Jeans 5"/>*/}
                                {/*</button>*/}
                            </div>
                        </div>

                        <div className="col-lg-5 pl-lg-4 order-1 order-lg-2">
                            <h1 className="mb-4">{nft.chainCore?.metadata.name || <span>&nbsp;</span>}</h1>
                            <div
                                className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between mb-4">
                                <ul className="list-inline mb-2 mb-sm-0">
                                    <li className="list-inline-item h4 font-weight-light mb-0">{nft.chainSale!=null && nft.chainSale.ask!=null && `${ethers.constants.EtherSymbol}${formatWei(nft.chainSale.ask)}`}</li>
                                </ul>
                            </div>
                            <p className="mb-4 text-muted">{multiLineDescription(nft)?.map((l: string) =>
                                <React.Fragment>{l}<br/></React.Fragment>
                            )}</p>
                            <BuyForm nft={nft} />
                        </div>
                    </div>
                </div>
            </section>

            {/* Redeem form */}
            {canRedeem(now, nft, stateWallet) &&
            <div className="py-6 text-muted">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 pr-lg-5 pr-xl-6 mb-5 mb-lg-0">
                    <h6 className="text-dark letter-spacing-1 mb-4">Redeem NFT</h6>
                    <p className="text-sm mb-3">If you are ready to redeem this NFT, then submit your details and we'll be in touch.</p>
                    <RedeemForm nft={nft}/>
                  </div>
                </div>
              </div>
            </div>
            }

            <section className="mt-5">
                <div className="container">
                    <ul className="nav nav-tabs flex-column flex-sm-row" role="tablist">
                        {nft.offchainMeta!=null && <li className="nav-item"><a className="nav-link detail-nav-link active" data-toggle="tab" href="#offchain-about" role="tab">About Artist</a></li> }
                        {nft.offchainMeta!=null && nft.offchainMeta.tab1!=null && <li className="nav-item"><a className="nav-link detail-nav-link" data-toggle="tab" href="#offchain-tab1" role="tab">{nft.offchainMeta.tab1.heading}</a></li>}
                        {nft.chainAuction!=null && <li className="nav-item"><a className="nav-link detail-nav-link" data-toggle="tab" href="#bid-history" role="tab">Bid History</a></li> }
                        <li className="nav-item"><a className="nav-link detail-nav-link" data-toggle="tab" href="#nft-details" role="tab">NFT Details</a></li>
                    </ul>
                    <div className="tab-content py-4">
                        {/*Tab1*/}
                        {nft.offchainMeta!=null && nft.offchainMeta.tab1!=null &&
                        <div className="tab-pane fade px-3" id="offchain-tab1" role="tabpanel">
                          <div className="row">
                            <div className="col-md-7">
                              <h5>{nft.offchainMeta.tab1.heading}</h5>
                                {nft.offchainMeta.tab1.body.map(p =>
                                    <p className="text-muted">{p.map(l => <React.Fragment>{markdownToReact(l)}<br/></React.Fragment>) }</p>
                                )}
                            </div>
                              {/*<div className="col-md-5"><img className="img-fluid" src="/img/product/detail-3.jpg"*/}
                              {/*                               alt=""/></div>*/}
                          </div>
                        </div>
                        }
                        {/*About*/}
                        {nft.offchainMeta!=null &&
                        <div className="tab-pane fade show active px-3" id="offchain-about" role="tabpanel">
                          <div className="row">
                            <div className="col-md-7">
                              <h5>{nft.offchainMeta.artist.heading}</h5>
                                {nft.offchainMeta.artist.body.map(p =>
                                    <p className="text-muted">{p.map(l => <React.Fragment>{markdownToReact(l)}<br/></React.Fragment>) }</p>
                                )}
                            </div>
                              {/*<div className="col-md-5"><img className="img-fluid" src="/img/product/detail-3.jpg"*/}
                              {/*                               alt=""/></div>*/}
                          </div>
                        </div>
                        }
                        {nft.chainAuction != null && <div className="fade tab-pane" id="bid-history" role="tabpanel">
                            <BidHistory nft={nft}/>
                          </div>
                        }
                        <div className="tab-pane fade px-3" id="nft-details" role="tabpanel">
                            <div className="row">
                                <div className="col-md-7">
                                   Proof of authenticity: <a href={etherscanZoraAuth(nft.core.tokenId)} target={"_blank"}>Etherscan transaction</a>
                                </div>
                            </div>
                            { nft.chainCore && nft.chainCore.bidShares &&
                                <div className="row">
                                  <div className="col-md-7">
                                    Resale Royalty: {nft.chainCore.bidShares.creator}%
                                  </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-md-7">
                                    View on <a href={openseaListing(nft.core.tokenId)} target={"_blank"}>OpenSea</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7">
                                    <a href="https://www.scramble.media/nft" target="_blank">NFT Terms</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            { /* Quickview Modal */}
            <div className="modal fade quickview" id="quickViewNFT" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <button className="close close-absolute close-rotate" type="button" data-dismiss="modal"
                                aria-label="Close">
                            <svg className="svg-icon w-3rem h-3rem svg-icon-light align-middle">
                                <use xlinkHref="#close-1"></use>
                            </svg>
                        </button>
                        <div className="modal-body quickview-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="detail-carousel">
                                        <div className="product-badge badge badge-primary">Fresh</div>
                                        <div className="product-badge badge badge-dark">Sale</div>
                                        <div className="swiper-container quickview-slider" id="quickViewSlider">
                                            { /* Additional required wrapper */}
                                            <div className="swiper-wrapper">
                                                { /* Slides */}
                                                <div className="swiper-slide"><img className="img-fluid"
                                                                                   src="/img/product/detail-1-gray.jpg"
                                                                                   alt="Modern Jacket 1"/></div>
                                                <div className="swiper-slide"><img className="img-fluid"
                                                                                   src="/img/product/detail-2-gray.jpg"
                                                                                   alt="Modern Jacket 2"/></div>
                                                <div className="swiper-slide"><img className="img-fluid"
                                                                                   src="/img/product/detail-3-gray.jpg"
                                                                                   alt="Modern Jacket 3"/></div>
                                                <div className="swiper-slide"><img className="img-fluid"
                                                                                   src="/img/product/detail-4-gray.jpg"
                                                                                   alt="Modern Jacket 4"/></div>
                                                <div className="swiper-slide"><img className="img-fluid"
                                                                                   src="/img/product/detail-5-gray.jpg"
                                                                                   alt="Modern Jacket 5"/></div>
                                            </div>
                                        </div>
                                        <div className="swiper-thumbs" data-swiper="#quickViewSlider">
                                            <button className="swiper-thumb-item detail-thumb-item mb-3 active"><img
                                                className="img-fluid" src="/img/product/detail-1-gray.jpg"
                                                alt="Modern Jacket 0"/></button>
                                            <button className="swiper-thumb-item detail-thumb-item mb-3"><img
                                                className="img-fluid" src="/img/product/detail-2-gray.jpg"
                                                alt="Modern Jacket 1"/></button>
                                            <button className="swiper-thumb-item detail-thumb-item mb-3"><img
                                                className="img-fluid" src="/img/product/detail-3-gray.jpg"
                                                alt="Modern Jacket 2"/></button>
                                            <button className="swiper-thumb-item detail-thumb-item mb-3"><img
                                                className="img-fluid" src="/img/product/detail-4-gray.jpg"
                                                alt="Modern Jacket 3"/></button>
                                            <button className="swiper-thumb-item detail-thumb-item mb-3"><img
                                                className="img-fluid" src="/img/product/detail-5-gray.jpg"
                                                alt="Modern Jacket 4"/></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-lg-5">
                                    <h2 className="mb-4 mt-4 mt-lg-1">Push-up Jeans</h2>
                                    <div
                                        className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between mb-4">
                                        <ul className="list-inline mb-2 mb-sm-0">
                                            <li className="list-inline-item h4 font-weight-light mb-0">$65.00</li>
                                            <li className="list-inline-item text-muted font-weight-light">
                                                <del>$90.00</del>
                                            </li>
                                        </ul>
                                        <div className="d-flex align-items-center text-sm">
                                            <ul className="list-inline mr-2 mb-0">
                                                <li className="list-inline-item mr-0"><i
                                                    className="fa fa-star text-primary"></i></li>
                                                <li className="list-inline-item mr-0"><i
                                                    className="fa fa-star text-primary"></i></li>
                                                <li className="list-inline-item mr-0"><i
                                                    className="fa fa-star text-primary"></i></li>
                                                <li className="list-inline-item mr-0"><i
                                                    className="fa fa-star text-primary"></i></li>
                                                <li className="list-inline-item mr-0"><i
                                                    className="fa fa-star text-gray-300"></i></li>
                                            </ul>
                                            <span className="text-muted text-uppercase">25 reviews</span>
                                        </div>
                                    </div>
                                    <p className="mb-4 text-muted">Samsa was a travelling salesman - and above it there
                                        hung a picture that he had recently cut out of an illustrated magazine and
                                        housed in a nice, gilded frame.</p>
                                    <form id="buyForm_modal" action="#">
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-12 detail-option mb-4">
                                                <h6 className="detail-option-heading">Size <span>(required)</span></h6>
                                                <select className="selectpicker" name="size"
                                                        data-style="btn-selectpicker">
                                                    <option value="value_0">Small</option>
                                                    <option value="value_1">Medium</option>
                                                    <option value="value_2">Large</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-6 col-lg-12 detail-option mb-5">
                                                <h6 className="detail-option-heading">Type <span>(required)</span></h6>
                                                <label
                                                    className="btn btn-sm btn-outline-primary detail-option-btn-label"
                                                    htmlFor="material_0_modal"> Hoodiex
                                                    <input className="input-invisible" type="radio" name="material"
                                                           value="value_0" id="material_0_modal" required/>
                                                </label>
                                                <label
                                                    className="btn btn-sm btn-outline-primary detail-option-btn-label"
                                                    htmlFor="material_1_modal"> College
                                                    <input className="input-invisible" type="radio" name="material"
                                                           value="value_1" id="material_1_modal" required/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-group w-100 mb-4">
                                            <input className="form-control form-control-lg detail-quantity" name="items"
                                                   type="number" value="1"/>
                                            <div className="input-group-append flex-grow-1">
                                                <button className="btn btn-dark btn-block" type="submit"><i
                                                    className="fa fa-shopping-cart mr-2"></i>Add to Cart
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-6"><a href="#"> <i className="far fa-heart mr-2"></i>Add
                                                to wishlist </a></div>
                                            <div className="col-6 text-right">
                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item mr-2"><a
                                                        className="text-dark text-hover-primary" href="#"><i
                                                        className="fab fa-facebook-f"> </i></a></li>
                                                    <li className="list-inline-item"><a
                                                        className="text-dark text-hover-primary" href="#"><i
                                                        className="fab fa-twitter"> </i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <ul className="list-unstyled">
                                            <li><strong>Category:</strong> <a className="text-muted" href="#">Jeans</a>
                                            </li>
                                            <li><strong>Tags:</strong> <a className="text-muted"
                                                                          href="#">Leisure</a>, <a
                                                className="text-muted" href="#">Elegant</a></li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
