import {InfuraType, StateTypeNFT} from "../redux/globalReducers";
import {AppDispatch} from "../redux/store";
import {getNFTCore} from "./getNFTCore";
import {getNFTSale} from "./getNFTSale";
import {getNFTAuction} from "./getNFTAuction";

export const getNFT = (infura: InfuraType, nft: StateTypeNFT, dispatch: AppDispatch) => {
    getNFTCore(infura, nft.core.tokenId, dispatch)
        .then(_ => {
            if (nft.core.auctionId == null) return getNFTSale(infura, nft.core.tokenId, dispatch)
            else return getNFTAuction(infura, nft.core.tokenId, nft.core.auctionId, dispatch)
        })
        .then(_ => {});
}
