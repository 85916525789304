/*
 Handle Media.setOffer to match an ask (ie. buy now). Wraps eth if needed.

 input: tokenId, buyer wallet, known ask

 1. check wallet weth balance
  1.2 wrap eth flow
 2. send weth
 3. sold

 */

import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {BigNumber, ethers} from "ethers";
import {globalMergeWallet} from "../redux/globalActions";
import {InfuraType, StateTypeNFT} from "../redux/globalReducers";
import {getNFTSale} from "../contract/getNFTSale";
import {getNFTAuction} from "../contract/getNFTAuction";
import {
    accountIsWinnerOrLastBidBool,
    AUCTION_STATE_FINISHED,
    AUCTION_STATE_LIVE,
    AUCTION_STATE_PRE,
    auctionRemainingSec,
    auctionState, canRedeem, formatBigNumber,
    formatDurationSec, formatWei,
    priceToShow,
    stringToString
} from "./common";
import {zoraAuctionCreateBid} from "../contract/createBid";
import {scrambleNFTBuy} from "../contract/scrambleNFTBuy";

const msgCouldNotConnect = "Could not connect wallet"

// first bid can be reserve other must be greater than last bid
// minBidIncrementPercentage = 5%
const validateBid = (bidString: string | null, nft: StateTypeNFT) => {
    const minBidIncrementPercentage = 5 // 5% from AuctionHouse.sol
    if (bidString == null || bidString == "" || nft.chainAuction == null) return false
    else {
        try {
            const bid = ethers.utils.parseEther(bidString)
            if (nft.chainAuction.bidHistory.length == 0) return bid.gte(nft.chainAuction.reserve)
            else {
                const lastBid = nft.chainAuction.bidHistory[nft.chainAuction.bidHistory.length - 1].amount
                return bid.gte(
                    lastBid.add(
                        lastBid.mul(BigNumber.from(minBidIncrementPercentage)).div(BigNumber.from(100)))
                )
            }
        } catch (e) {
            return false;
        }
    }
}

interface ParamTypes {
    nft: StateTypeNFT
}

const AuctionRemainingSecondsTimeout = 1000

export const BuyForm = ({nft}: ParamTypes) => {
    const stateWallet = useAppSelector((state) => state.wallet)
    const stateInfura = useAppSelector((state) => state.infura)
    const [auctionRemainingSeconds, setAuctionRemainingSeconds] = useState<number>(0)
    const dispatch = useAppDispatch()

    useEffect(() => {
        // update auctionRemainingSeconds
        let timer: NodeJS.Timeout | null = null
        const f = () => {
            setAuctionRemainingSeconds(auctionRemainingSec(new Date(), nft))
            timer = setTimeout(f, AuctionRemainingSecondsTimeout)
        }
        timer = setTimeout(f, AuctionRemainingSecondsTimeout)

        return () => {
            if (timer != null) clearTimeout(timer)
        }
    }, [nft])

    useEffect(() => {
        // clear wallet state on load
        dispatch(globalMergeWallet({isBusy: false, messageInfo: null, messageError: null, bid: null}))

        // init wallet provider
        if (window.ethereum != undefined && stateWallet.provider == null) {
            const p = new ethers.providers.Web3Provider(window.ethereum, "any")
            dispatch(globalMergeWallet({provider: p}))

            // Note that this event is emitted on page load.
            // If the array of accounts is non-empty, you're already
            // connected.
            p.listAccounts().then(
                onFulfilled => {
                    console.log('listAccounts: ' + JSON.stringify(onFulfilled))
                    // array of accounts
                    if (onFulfilled.length > 0) dispatch(globalMergeWallet({account: onFulfilled[0]}))
                },
                onRejected => {
                    console.log(JSON.stringify(onRejected))
                }
            )
        }

        const accountsChanged = (a: string) => {
            console.log('accountsChanged ' + a)
            dispatch(globalMergeWallet({account: a}))
        }

        if (window.ethereum != undefined) window.ethereum.on('accountsChanged', accountsChanged);
        return () => {
            if (window.ethereum != undefined) {
                window.ethereum.removeListener('accountsChanged', accountsChanged)
            }
        }
    }, []);

    const doConnectWallet = async () => {
        // Prompt user for account connections
        console.log('before eth_requestAccounts')
        // @ts-ignore
        stateWallet.provider.send("eth_requestAccounts", [])
            .then(
                onFulfilled => {
                    // onFulfilled: string[] account names?
                    // getAddress() string "0x..."

                    console.log('before getSigner')
                    // @ts-ignore
                    const signer = stateWallet.provider.getSigner()
                    signer.getAddress().then(
                        onFulfilled => {
                            console.log(JSON.stringify(onFulfilled))
                            dispatch(globalMergeWallet({account: onFulfilled, messageError: null}))
                        },
                        onRejected => {
                            console.log(JSON.stringify(onRejected))
                            dispatch(globalMergeWallet({messageError: msgCouldNotConnect}))
                        }
                    )
                },
                onRejected => {
                    // eg. Object { code: -32002, message: "Already processing eth_requestAccounts. Please wait." }
                    console.log(JSON.stringify(onRejected))
                    dispatch(globalMergeWallet({messageError: msgCouldNotConnect}))
                }
            )
            .catch(err => {
                console.log('eth_requestAccounts ' + err)
                dispatch(globalMergeWallet({messageError: msgCouldNotConnect}))
            })
    }

    const doEthBuy = async (infura: InfuraType, tokenId: number, amount: BigNumber) => {
        dispatch(globalMergeWallet({isBusy: true}))

        scrambleNFTBuy(infura, tokenId, amount /*stateWallet.account*/)
            .then(
                buy => {
                    console.log('scrambleNFTBuy: ' + JSON.stringify(buy))
                    const s = stateWallet.provider?.getSigner()
                    s?.sendTransaction(buy)
                        .then(async r => {
                            console.log('sendTransaction ' + r)
                            r.wait()
                                .then(() => // wait for chain commit
                                    getNFTSale(infura, tokenId, dispatch, true).then(() => {// update chain data
                                        // stop spinny in getNFTSale for UI update order
                                        dispatch(globalMergeWallet({messageError: null}))
                                    }) // TODO catch getNFTSale error - stop spinny??
                                )
                                .catch(err => {
                                    console.log('sendTransaction(setBid ' + JSON.stringify(err))
                                    dispatch(globalMergeWallet({isBusy: false, messageError: "Error"}))
                                })

                        })
                        .catch(err => {
                            console.log('sendTransaction(buyNFT ' + JSON.stringify(err))
                            dispatch(globalMergeWallet({isBusy: false, messageError: "Error"}))
                        })
                }
            )
            .catch(err => {
                console.log('buyNFT catch: ' + JSON.stringify(err))
                dispatch(globalMergeWallet({isBusy: false, messageError: "Error"}))
            })
    }

    const doBid = async (infura: InfuraType, tokenId: number, auctionId: number, amount: BigNumber) => {
        dispatch(globalMergeWallet({isBusy: true}))

        zoraAuctionCreateBid(infura, auctionId, amount, stateWallet.account!).then(bid => {
            console.log('before provider?.sendTransaction: ' + JSON.stringify(bid));
            const s = stateWallet.provider?.getSigner();
            s?.sendTransaction(bid)
                .then(async r => {
                    console.log('sendTransaction ' + r)
                    r.wait().then(() => // wait for chain commit
                        getNFTAuction(infura, tokenId, auctionId, dispatch, true).then(() => { // update chain data
                            // stop spinny in getNFTSale for UI update order
                            dispatch(globalMergeWallet({bid: null, messageError: null}))
                        })
                    )
                        .catch(err => {
                            console.log('sendTransaction(setBid ' + JSON.stringify(err))
                            dispatch(globalMergeWallet({isBusy: false, messageError: "Error"}))
                        })

                })
                .catch(err => {
                    console.log('sendTransaction(setBid ' + JSON.stringify(err))
                    dispatch(globalMergeWallet({isBusy: false, messageError: "Error"}))
                });
        })
        .catch(err => {
            console.log('createBid: ' + JSON.stringify(err))
            dispatch(globalMergeWallet({isBusy: false, messageError: "Error"}))
        })
    }

    const bidValid = validateBid(stateWallet.bid, nft)
    const now = new Date()
    const nftAuctionState = auctionState(now, nft)
    const roundTripBidValue = (bidString: string | null) => {
        if (bidString == null) return ""
        else {
            try {
                const bid = ethers.utils.parseEther(bidString)
                return `${ethers.constants.EtherSymbol}${ethers.utils.formatEther(bid)}`
            } catch (_) {
                return ""
            }
        }
    }
    const accountIsWinnerOrLastBid = accountIsWinnerOrLastBidBool(nft, stateWallet)

    return (
        <form id="buyForm" action="#">
            {(stateWallet.messageInfo || stateWallet.messageError) &&
              <div className="row">
                <div className="col-sm-6 col-lg-12 detail-option mb-1">
                  <span className="text-danger">{stateWallet.messageInfo}</span>
                </div>
                <div className="col-sm-6 col-lg-12 detail-option mb-1">
                  <span className="text-danger">{stateWallet.messageError}</span>
                </div>
              </div>
            }

            {stateWallet.account != null &&
              <div className="row">
                <div className="col-sm-6 col-lg-12 detail-option mb-2">
                  Wallet <span className="text">{stateWallet.account}</span>
                </div>
              </div>
            }

            {/*"Need Metamask" if still for sale and no metamask installed */}
            {window.ethereum == undefined /*&& nft.chainSale!=null && nft.chainSale.ask!=null*/ && <div className="row">
              <div className="col-sm-6 col-lg-12 detail-option mb-5">
                <h2 className="text-info">Please Install <a href="https://metamask.io/" target="_blank">Metamask</a>
                </h2>
              </div>
            </div>
            }

            {/* Connect account */}
            {window.ethereum != undefined && stateWallet.account == null &&
              <div className="row">
                <div className="input-group w-100 mb-4">
                  <button className="btn btn-dark btn-block btn-lg" type="submit" onClick={e => {
                      doConnectWallet();
                      e.preventDefault();
                  }}>
                    <i className="fa fa-shopping-cart mr-2"></i>Connect Wallet
                  </button>
                </div>
              </div>
            }

            {/* Sale - Sold Out */}
            {nft.chainSale != null && nft.chainSale.ask == null && nft.chainSale.winner != null &&
              <React.Fragment>
                <div className="row">
                  <div className="col-sm-6 col-lg-12 detail-option mb-5">
                    <h2>{
                        nft.chainSale.winner != null && nft.chainSale.winner.recipient == stateWallet.account
                            ? <React.Fragment>
                                <span className="badge badge-success mb-2">Congratulations - You Own This NFT</span>
                                {nft.core.redeemable && <React.Fragment>
                                  <br/>
                                  <span className="text-muted">Scroll down to redeem &darr;</span>
                                </React.Fragment>}
                            </React.Fragment>
                            : <span className="badge badge-warning">SOLD</span>
                    }</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10">
                    <ul className="list-unstyled">
                      <li><strong>Sale Price: </strong><span
                        className="text-muted">{formatBigNumber(nft.chainSale.winner.amount)}</span></li>
                    </ul>
                  </div>
                </div>
              </React.Fragment>
            }

            {/* Auciton Finished */}
            {nftAuctionState == AUCTION_STATE_FINISHED &&
              <div className="row">
                <div className="col-sm-6 col-lg-12 detail-option mb-5">
                  <h2>{
                      accountIsWinnerOrLastBid
                          ? <React.Fragment>
                              <span className="badge badge-success">Congratulations - You Own This NFT</span>
                              {nft.core.redeemable && <React.Fragment>
                                <br/>
                                <span className="text-muted">Scroll down to redeem &darr;</span>
                              </React.Fragment>}
                          </React.Fragment>
                          : <span className="badge badge-warning">SOLD</span>
                  }</h2>
                </div>
              </div>
            }

            {/*Buy*/}
            {nft.chainSale != null && nft.chainSale.ask != null && stateWallet.account != null &&
              <div className="row">
                <div className="col-10">
                  <button className="btn btn-dark btn-block btn-lg" disabled={stateWallet.isBusy} type="submit"
                          onClick={e => {// @ts-ignore
                              doEthBuy(stateInfura, nft.core.tokenId, nft.chainSale.ask);
                              e.preventDefault();
                          }}>
                    <i className="fa fa-shopping-cart mr-2"></i>BUY
                    NOW {ethers.constants.EtherSymbol}{formatWei(nft.chainSale.ask)}
                  </button>
                </div>
                  {stateWallet.isBusy &&
                    <div className="col-2">
                      <div className="spinner-border text-dark mt-2" role="status">
                        <span className="sr-only">Processing...</span>
                      </div>
                    </div>
                  }
              </div>
            }

            {/*Yours is highest bid*/}
            {(nftAuctionState == AUCTION_STATE_PRE || nftAuctionState == AUCTION_STATE_LIVE) && stateWallet.account != null && accountIsWinnerOrLastBid &&
              <div className="row">
                <div className="col-sm-6 col-lg-12 detail-option mb-5">
                  <h2><span className="badge badge-primary">You are the highest bidder.</span></h2>
                </div>
              </div>
            }

            {/*Bid*/}
            {(nftAuctionState == AUCTION_STATE_PRE || nftAuctionState == AUCTION_STATE_LIVE) && stateWallet.account != null && !accountIsWinnerOrLastBid &&
              <React.Fragment>
                <div className="row">
                  <div className="col-10">
                    <div className="input-group input-group-underlined mb-3">
                        {/*  <div className="input-group-prepend">*/}
                        {/*    <div className="input-group-text">{ethers.constants.EtherSymbol}</div>*/}
                        {/*  </div>*/}
                      <input className="form-control form-control-underlined" type="text"
                             placeholder="Your Bid" aria-label="Your Bid" value={stringToString(stateWallet.bid)}
                             disabled={stateWallet.isBusy}
                             onChange={e => dispatch(globalMergeWallet({bid: e.target.value}))}
                      />
                      <div className="input-group-append ml-0">
                        <button className="btn btn-underlined text-gray-700 py-0" type="button"
                                disabled={stateWallet.isBusy}
                                onClick={_ => dispatch(globalMergeWallet({bid: null}))}>
                          <svg className="svg-icon w-2rem h-2rem">
                            <use xlinkHref="#close-1"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10">
                    <button className="btn btn-dark btn-block btn-lg" disabled={stateWallet.isBusy || !bidValid}
                            type="submit"
                            onClick={e => {// @ts-ignore
                                doBid(stateInfura, nft.core.tokenId, nft.core.auctionId, ethers.utils.parseEther(stateWallet.bid));
                                e.preventDefault();
                            }}>
                      <i className="fa fa-shopping-cart mr-2"></i>BID {bidValid && roundTripBidValue(stateWallet.bid)}
                    </button>
                  </div>
                    {stateWallet.isBusy &&
                      <div className="col-2">
                        <div className="spinner-border text-dark mt-2" role="status">
                          <span className="sr-only">Processing...</span>
                        </div>
                      </div>
                    }
                </div>
              </React.Fragment>
            }

            {/* Auction not yet started */}
            {nftAuctionState == AUCTION_STATE_PRE && nft.chainAuction != null &&
              <div className="row">
                <div className="col-10">
                  Auction will commence on first bid.
                  <ul className="list-unstyled">
                    <li><strong>Reserve: </strong><span className="text-muted">{priceToShow(nft)}</span></li>
                    <li><strong>Duration: </strong><span
                      className="text-muted">{formatDurationSec(nft.chainAuction.duration)}</span></li>
                  </ul>
                </div>
              </div>
            }

            {/* Auction started */}
            {nftAuctionState == AUCTION_STATE_LIVE && nft.chainAuction &&
              <div className="row">
                <div className="col-10">
                  Auction live. Minimum bid increment 5%.
                  <ul className="list-unstyled">
                    <li><strong>Current Bid: </strong><span className="text-muted">{priceToShow(nft)}</span></li>
                    <li><strong>Time Remaining: </strong><span
                      className="text-muted">{formatDurationSec(auctionRemainingSeconds)}</span></li>
                  </ul>
                </div>
              </div>
            }

            {/* Auction finished (winner null until final bid accepted) */}
            {nftAuctionState == AUCTION_STATE_FINISHED && nft.chainAuction &&
              <div className="row">
                <div className="col-10">
                  Auction finished.
                  <ul className="list-unstyled">
                    <li><strong>Reserve: </strong><span
                      className="text-muted">{formatBigNumber(nft.chainAuction.reserve)}</span></li>
                    <li><strong>Final Bid: </strong><span
                      className="text-muted">{formatBigNumber(nft.chainAuction.bidHistory[nft.chainAuction.bidHistory.length - 1].amount)}</span>
                    </li>
                    <li><strong>Duration: </strong><span
                      className="text-muted">{formatDurationSec(nft.chainAuction.duration)}</span></li>
                  </ul>
                </div>
              </div>
            }

            {/*<div className="row mb-4">*/}
            {/*    <div className="col-6"></div>*/}
            {/*    <div className="col-6 text-right">*/}
            {/*        <ul className="list-inline mb-0">*/}
            {/*            <li className="list-inline-item mr-2"><a*/}
            {/*                className="text-dark text-hover-primary" href="#"><i*/}
            {/*                className="fab fa-facebook-f"> </i></a></li>*/}
            {/*            <li className="list-inline-item"><a className="text-dark text-hover-primary"*/}
            {/*                                                href="#"><i*/}
            {/*                className="fab fa-twitter"> </i></a></li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </form>
    )
}
