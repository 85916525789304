import {initialRedeem, RedeemType, StateTypeNFT} from "../redux/globalReducers";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {globalRedeemInit, globalRedeemSet} from "../redux/globalActions";
import {postRedeem} from "./postRedeem";
import React, {useEffect} from "react";

interface ParamTypes {
    nft: StateTypeNFT
}

const makeMessage = (nft: StateTypeNFT, redeem: RedeemType) => {
    return `NFT TokenID: ${nft.core.tokenId}
Name: ${redeem.name}
Email: ${redeem.email}
Phone: ${redeem.phone}
Address:
${redeem.address}
Comment:
${redeem.comment}
`
}

const isValid = (redeem: RedeemType) => {
  return redeem.name!="" && redeem.email!="" && redeem.phone!="" && redeem.address!="";
}

export const RedeemForm = ({nft}: ParamTypes) => {
    const stateWallet = useAppSelector((state) => state.wallet)
    const stateRedeem = useAppSelector((state) => state.redeem)
    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(globalRedeemInit())
        }
    }, [])

    const doOnChange = (m: any) => {
        dispatch(globalRedeemSet({
            ...stateRedeem,
            ...m
        }))
    }

    const doRedeem = (nft: StateTypeNFT, redeem: RedeemType) => {
        doOnChange({isBusy: true});

        const message = makeMessage(nft, redeem)
        stateWallet.provider?.getSigner().signMessage(message).then(
            signature => { // onFulfilled
                console.log('signature: '+ signature);
                postRedeem(stateWallet.account||"", message, signature)
                    .then(success => {
                        console.log(success)
                        if(success) dispatch(globalRedeemSet({...initialRedeem, status: "Thanks!"}))
                        else doOnChange({error: "Error", isBusy: false})
                    })
                    .catch(err => {
                        console.log(err);
                        doOnChange({error: "Error", isBusy: false});
                    })
            },
            onRejected => {
                console.log('onRejected: '+onRejected);
                doOnChange({error: "Error", isBusy: false});
            },
        ).catch(err => {
            console.log(err);
            doOnChange({error: "Error", isBusy: false});
        })
    }

    return (
    <form action="customer-orders.html" method="get">
        <div className="form-group row">
            <label className="form-label col-2" htmlFor="redeem-name">* Name</label>
            <div className="col-10">
              <input className="form-control" id="redeem-name" type="text" value={stateRedeem.name} onChange={e => doOnChange({name: e.target.value}) } />
            </div>
        </div>
        <div className="form-group row">
            <label className="form-label col-2" htmlFor="redeem-email">* Email</label>
            <div className="col-10">
              <input className="form-control" id="redeem-email" type="text" value={stateRedeem.email} onChange={e => doOnChange({email: e.target.value}) }/>
            </div>
        </div>
        <div className="form-group row">
            <label className="form-label col-4" htmlFor="redeem-phone">* Phone</label>
            <div className="col-8">
              <input className="form-control" id="redeem-phone" type="text" value={stateRedeem.phone} onChange={e => doOnChange({phone: e.target.value}) }/>
            </div>
        </div>
        <div className="form-group row">
            <label className="form-label col-2" htmlFor="redeem-address">* Shipping Address</label>
            <div className="col-10">
                <textarea className="form-control" id="redeem-address" onChange={e => doOnChange({address: e.target.value}) }>{stateRedeem.address}</textarea>
            </div>
        </div>
        <div className="form-group row">
            <label className="form-label col-2" htmlFor="redeem-comment">Comment</label>
            <div className="col-10">
                <textarea className="form-control" id="redeem-comment" onChange={e => doOnChange({comment: e.target.value}) }>{stateRedeem.comment}</textarea>
            </div>
        </div>
        <div className="text-sm float-right">* Required</div>
        <button className="btn btn-dark" type="submit" disabled={!isValid(stateRedeem)} onClick={e => {doRedeem(nft, stateRedeem); e.preventDefault();}}>SIGN & REDEEM</button>
        {stateRedeem.error!="" && <span className="text-danger text-sm ml-4">{stateRedeem.error}</span>}
        {stateRedeem.status!="" && <span className="text-info ml-4">{stateRedeem.status}</span>}
        {stateRedeem.isBusy &&
            <div className="spinner-border text-dark ml-2 align-bottom mb-1" role="status">
            <span className="sr-only">Processing...</span>
        </div>
        }
    </form>
    )
}
