import React, {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getSetInitialState} from "./getInitialState";

type PageProps = {
    showHomeLink: boolean
    dropId: string
    children: any
}

export const Page = ({showHomeLink, dropId, children}: PageProps) => {

    const state = useAppSelector((state) => state)

    // first load fetch nft initial state
    const dispatch = useAppDispatch()
    useEffect(() => {
        getSetInitialState(dispatch);
    }, []);

    return (
        <React.Fragment>
            <header className="header header-absolute">
                { /* Top Bar */}
                <div className="top-bar text-sm opacity-5">
                    <div className="container-fluid px-lg-5 py-1">
                        <div className="row align-items-center">
                            <div className="col d-none d-md-block">
                                <ul className="list-inline mb-0">
                                    {!showHomeLink && <React.Fragment>
                                          <li className="list-inline-item mr-2">
                                            <a href="https://scramble.media" target="_blank" className="text-reset text-hover-primary">
                                              <h5><span className="nav-link">Scramble Media</span></h5>
                                            </a>
                                          </li>
                                        {state.menu.map(m =>
                                          <li className="list-inline-item mr-2">
                                              {m==dropId && <h5><span className="text-dark">{state.drops[m].label}</span></h5>}
                                              {m!=dropId && <Link to={`/drop/${m}`} className="text-reset text-hover-primary">
                                                  <h5><span className="nav-link">{state.drops[m].label}</span></h5>
                                                </Link>}
                                          </li>
                                        )}
                                    </React.Fragment>
                                    }
                                    {showHomeLink &&
                                        <li className="list-inline-item mr-2">
                                          <Link to={`/drop/${dropId}`} className="text-reset text-hover-primary">
                                            <h5><span className="text-info">Drop Top</span></h5>
                                          </Link>
                                        </li>
                                    }
                                    {/*<li className="list-inline-item mr-2"><a*/}
                                    {/*    className="text-reset text-hover-primary"*/}
                                    {/*    href="#"><i*/}
                                    {/*    className="fab fa-facebook-f"> </i></a></li>*/}
                                    {/*<li className="list-inline-item mr-4"><a*/}
                                    {/*    className="text-reset text-hover-primary"*/}
                                    {/*    href="#"><i*/}
                                    {/*    className="fab fa-twitter"> </i></a></li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                { /* Top Bar End */}
                <nav
                    className="navbar navbar-expand-lg bg-transparent border-0 shadow-0 navbar-light px-lg-5 "></nav>
            </header>

            {children}

            { /* Footer */}
            <footer>
                { /* Copyright section of the footer */}
                <div className="py-4 font-weight-light text-muted">
                    <div className="container">
                        <div className="row align-items-center text-sm text-gray-500">
                            <div className="col-lg-4 text-center text-lg-left">
                                <p className="mb-lg-0">&copy; 2021 Vanten Media.</p>
                            </div>
                            <div className="col-lg-8">
                                <ul className="list-inline mb-0 mt-2 mt-md-0 text-center text-lg-right">
                                    <li className="list-inline-item">
                                        <a className="text-reset" href="https://www.scramble.media/nft" target="_blank">Terms &amp; Conditions </a></li>
                                    {/*<li className="list-inline-item"><a className="text-reset"*/}
                                    {/*                                    href="#">Privacy &amp; cookies </a></li>*/}
                                    {/*<li className="list-inline-item"><a className="text-reset"*/}
                                    {/*                                    href="#">Accessibility </a></li>*/}
                                    {/*<li className="list-inline-item"><a className="text-reset" href="#">Customer*/}
                                    {/*    Data*/}
                                    {/*    Promise </a></li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            { /* /Footer end */}

            { /* Root element of PhotoSwipe. Must have class pswp. */}
            <div className="pswp" tabIndex={-1} role="dialog" aria-hidden="true">
                { /*}
                Background of PhotoSwipe.
                It's a separate element as animating opacity is faster than rgba().
                */}
                <div className="pswp__bg"></div>
                { /* Slides wrapper with overflow:hidden. */}
                <div className="pswp__scroll-wrap">
                    { /*
                    Container that holds slides.
                    PhotoSwipe keeps only 3 of them in the DOM to save memory.
                    Don't modify these 3 pswp__item elements, data is added later on.
                    */}
                    <div className="pswp__container">
                        <div className="pswp__item"></div>
                        <div className="pswp__item"></div>
                        <div className="pswp__item"></div>
                    </div>
                    { /* Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. */}
                    <div className="pswp__ui pswp__ui--hidden">
                        <div className="pswp__top-bar">
                            { /* Controls are self-explanatory. Order can be changed. */}
                            <div className="pswp__counter"></div>
                            <button className="pswp__button pswp__button--close" title="Close (Esc)"></button>
                            <button className="pswp__button pswp__button--share" title="Share"></button>
                            <button className="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
                            <button className="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
                            { /* Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR */}
                            { /* element will get class pswp__preloader--active when preloader is running */}
                            <div className="pswp__preloader">
                                <div className="pswp__preloader__icn">
                                    <div className="pswp__preloader__cut">
                                        <div className="pswp__preloader__donut"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                            <div className="pswp__share-tooltip"></div>
                        </div>
                        <button className="pswp__button pswp__button--arrow--left"
                                title="Previous (arrow left)"></button>
                        <button className="pswp__button pswp__button--arrow--right"
                                title="Next (arrow right)"></button>
                        <div className="pswp__caption">
                            <div className="pswp__caption__center text-center"></div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
