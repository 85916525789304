import {
    BUY_NONE,
    BUY_CONNECT,
    GLOBAL_SETNFT_CORE,
    GLOBAL_SETNFT_SALE,
    GLOBAL_SETNFT_AUCTION,
    GLOBAL_MERGE_WALLET, GLOBAL_SETNFT_INITIAL, GLOBAL_REDEEM_INITIAL, GLOBAL_REDEEM_SET
} from "./constants";
import {BigNumber, ethers} from "ethers";
import {initInfura} from "../contract/infura";

export interface StateTypeNFT {
    core: { tokenId: number, auctionId: number |null, redeemable: boolean },
    offchainMeta: {
        artist: {heading: string, body: ReadonlyArray<ReadonlyArray<string>>},
        altTokenUri: string | null,
        tab1: {heading: string, body: ReadonlyArray<ReadonlyArray<string>>} | null
    } | null
    chainCore: {
        tokenURI: string,
        metadata: { name: string, description: string, mimeType: string }
        bidShares: { creator: number } | null // %
    } | null,
    chainSale: {
        ask: BigNumber | null, // null after sale
        winner: {recipient: string, amount: BigNumber} | null
    } | null,
    chainAuction: {
        reserve: BigNumber,
        duration: number, // seconds, can be extended
        // auction finishes at: bidHistory[0].timestamp + duration
        // bid.timestamp and winner.timestamp in ms
        bidHistory: Array<{sender: string, amount: BigNumber, timestamp: number}>, // ordered ascending
        winner: {sender: string, amount: BigNumber, timestamp: number} | null
    } | null
}
export interface DropType {
    label: string,
    displayOrder: ReadonlyArray<string>, // ref to nft.[string]
    about: {heading: string, body: ReadonlyArray<ReadonlyArray<string>>} | null,
    splash: {text: string, image: string} | null
}

export interface InfuraType {
    provider: ethers.providers.Provider,
    zoraMarketContract: ethers.Contract,
    zoraMarketInterface: ethers.utils.Interface,
    zoraMediaContract: ethers.Contract,
    zoraMediaInterface: ethers.utils.Interface,
    zoraAuctionContract: ethers.Contract,
    zoraAuctionInterface: ethers.utils.Interface,
    scrambleNFTBuyContract: ethers.Contract,
    scrambleNFTBuyInterface: ethers.utils.Interface,
}

export interface StateType {
    // infura
    infura: InfuraType | undefined,
    // wallet connect and buy
    wallet: {
        messageInfo: string | null,
        messageError: string | null,
        provider: ethers.providers.Web3Provider | null,
        account: string | null, // wallet account
        bid: string | null, // bid input field
        isBusy: boolean
    },
    // nft state
    nft: {
        [key: string]: StateTypeNFT
    },
    // drops
    drops: {
        [key: string]: DropType
    },
    menu: ReadonlyArray<string>,
    redeem: RedeemType
}

export interface RedeemType {
    name: string,
    email: string,
    phone: string,
    address: string,
    comment: string,
    isBusy: boolean,
    error: string,
    status: string,
}

export const initialRedeem: RedeemType = {
    name: "",
    email: "",
    phone: "",
    address: "",
    comment: "",
    isBusy: false,
    error: "",
    status: "",
}

const initialState: StateType = {
    infura: initInfura(),
    wallet: {
        messageInfo: null,
        messageError: null,
        provider: null,
        account: null,
        bid: null,
        isBusy: false
    },
    nft: {
    },
    drops: {
    },
    menu: [],
    redeem: initialRedeem
}

export function reducer(state: StateType = initialState, action: any) {
    let nft: any = null

    switch (action.type) {
        // -- initial NFT from server side JSON config
        case GLOBAL_SETNFT_INITIAL:
            return {
                ...state,
                nft: action.nft,
                drops: action.drops,
                menu: action.menu
            }

        // --- update NFT
        case GLOBAL_SETNFT_CORE:
            nft = state.nft["" + action.tokenId]
            return {
                ...state,
                nft: {
                    ...state.nft,
                    ["" + action.tokenId]: {
                        ...nft,
                        chainCore: action.chain
                    }
                }
            };

        case GLOBAL_SETNFT_SALE:
            nft = state.nft["" + action.tokenId]
            return {
                ...state,
                nft: {
                    ...state.nft,
                    ["" + action.tokenId]: {
                        ...nft,
                        chainSale: action.chain
                    }
                }
            };

        case GLOBAL_SETNFT_AUCTION:
            nft = state.nft["" + action.tokenId]
            return {
                ...state,
                nft: {
                    ...state.nft,
                    ["" + action.tokenId]: {
                        ...nft,
                        chainAuction: action.chain
                    }
                }
            };

            // ---
        case GLOBAL_MERGE_WALLET:
            return {
                ...state,
                wallet: {
                    ...state.wallet,
                    ...action.wallet
                }
            }

        case GLOBAL_REDEEM_INITIAL:
            return {
                ...state,
                redeem: initialRedeem
            }

        case GLOBAL_REDEEM_SET:
            return {
               ...state,
               redeem: action.redeem
            }

        default:
            return state;
    }
}
