import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from "redux";
import {reducer as globalReducer} from "./globalReducers";

// const loggerMiddleware = createLogger();

const store = createStore(
    globalReducer,
    applyMiddleware(
        thunkMiddleware, // lets us dispatch() functions
        // loggerMiddleware // neat middleware that logs actions
    )
);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
