import axios from "axios";
import {InfuraType} from "../redux/globalReducers";
import {AppDispatch} from "../redux/store";
import {globalSetNftCore} from "../redux/globalActions";
import {ethers} from "ethers";

const ipfsScrambleProxy = (url: string) => `https://nft.scramble.media/ipfs/${url.substring(url.lastIndexOf('/') + 1)}`

const getMetadataIPFS = async (ipfsUrl: string) =>
    axios.get(ipfsUrl)
        .then(metadata => {
                return {name: metadata.data.name, description: metadata.data.description, mimeType: metadata.data.mimeType}
            }
        )

// //  bidshares: "{"owner": {"value": 90000000000000000000}, "creator": {"value": 10000000000000000000}, "prevOwner": {"value": 0}}"

function zoraBidShareUpdated(infura: InfuraType, tokenId: number) {
    let filter = infura.zoraMarketContract.filters.BidShareUpdated(tokenId, null);

    return infura.provider.getLogs({
        fromBlock: 0,
        toBlock: 'latest',
        address: infura.zoraMarketContract.address,
        topics: filter.topics,
    })
        .then(events => {
            if(events.length === 0) return null
            else {
                let raw = events[events.length - 1];
                let evt = infura.zoraMarketInterface.parseLog(raw);
                // TODO better wei to % conversion
                return { creator: +ethers.utils.formatEther(evt.args.bidShares.creator.value) }
            }
        });
}

function zoraMediaTokenURI(infura: InfuraType, tokenId: number) {
    return infura.zoraMediaContract.tokenURI(tokenId);
}

function zoraMediaMetadata(infura: InfuraType, tokenId: number) {
    return infura.zoraMediaContract.tokenMetadataURI(tokenId)
        .then(ipfsScrambleProxy)
        .then((ipfsUrl: string) => getMetadataIPFS(ipfsUrl));
}

export const getNFTCore = async (infura: InfuraType, tokenId: number, dispatch: AppDispatch) => {
    Promise.all([zoraMediaTokenURI(infura, tokenId), zoraMediaMetadata(infura, tokenId), zoraBidShareUpdated(infura, tokenId)])
        .then(([tokenURI, metadata, bidShares]) => {
            dispatch(globalSetNftCore(
                tokenId,
                {
                    tokenURI: ipfsScrambleProxy(tokenURI),
                    metadata: {name: metadata.name, description: metadata.description, mimeType: metadata.mimeType},
                    bidShares: bidShares
                }
            ))
        })
        .catch(err => console.log(err))
}
