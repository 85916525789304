import {BigNumber, ethers, utils} from "ethers";
import moment, {duration} from 'moment';
import React from "react";
import {StateType, StateTypeNFT} from "../redux/globalReducers";

// string or null to string or empty string
export const stringToString = (s: string | null) => s==null ? "" : s

// format wei BigNumber into and ether string
export const formatWei = (wei: BigNumber) => utils.formatEther(wei)

// format BigNumber wei to ether with Xi prefix or empty string for null
export const formatBigNumber = (wei: BigNumber | null) => wei==null ? "" : (ethers.constants.EtherSymbol + formatWei(wei))

// format unix epoch timestamp (eg. bid timestamp) into a UTC string
export const formatUnixMs = (unixMs: number) => moment(new Date(unixMs)).utc().format("YYYY-MM-DD HH:mm:ss") + " UTC"

// BigNumber or null, auction reserve or max bid
export const auctionPrice = (nft: StateTypeNFT) => {
    if(nft.chainAuction != null) {
        // TODO for now ignore winner as winner event reports (bid - bid share %)
        //if(nft.chainAuction.winner!=null) return nft.chainAuction.winner.amount
        if(nft.chainAuction.bidHistory.length>0) return nft.chainAuction.bidHistory[nft.chainAuction.bidHistory.length-1].amount
        else return nft.chainAuction.reserve
    } else {
        return null
    }
}

export const salePrice = (nft: StateTypeNFT) => {
    if(nft.chainSale==null) return null
    else if(nft.chainSale.winner!=null) return nft.chainSale.winner.amount
    else return nft.chainSale.ask // ask could be null (no winner and no ask !?)
}

// price in ether prefixed with eth symbol (Xi)
export const priceToShow = (nft: StateTypeNFT) => {
    let price = salePrice(nft)
    if(price==null) price = auctionPrice(nft)
    return price==null ? "" : (ethers.constants.EtherSymbol + formatWei(price))
}

export const formatDurationSec = (sec: number) => {
    const m = moment.utc(duration(sec, 'seconds').as('milliseconds'))
    const d = sec>=86400
        ? (Math.floor(sec/86400) + 'day ')
        : ""
    return d +
        m.format('HH') + 'h ' +
        m.format('mm') + 'm ' +
        m.format('ss') + 's'
}

export const AUCTION_STATE_UNKNOWN = "AUCTION_STATE_UNKNOWN"
export const AUCTION_STATE_PRE = "AUCTION_STATE_PRE" // no bids
export const AUCTION_STATE_LIVE = "AUCTION_STATE_LIVE" // one or more bids, within duration
export const AUCTION_STATE_FINISHED = "AUCTION_STATE_FINISHED" // one or more bids, post duration (winner may be null)

const SECONDS_MS = 1000
export const auctionState = (now: Date, nft: StateTypeNFT) => {
    if(nft.chainAuction==null) return AUCTION_STATE_UNKNOWN
    else if(nft.chainAuction.bidHistory.length==0) return AUCTION_STATE_PRE
    else {
        const firstBid = nft.chainAuction.bidHistory[0]
        return auctionRemainingSec(now, nft) > 0
          ? AUCTION_STATE_LIVE
          : AUCTION_STATE_FINISHED;
    }
}

export const accountIsWinnerOrLastBidBool = (nft: StateTypeNFT, wallet: StateType["wallet"]) => {
    return wallet!=null && wallet.account!=null && nft.chainAuction!=null &&
    ((nft.chainAuction.winner!=null && nft.chainAuction.winner.sender==wallet.account) ||
        (nft.chainAuction.bidHistory.length>0 &&
            nft.chainAuction.bidHistory[nft.chainAuction.bidHistory.length-1].sender==wallet.account))
}

export const canRedeem = (now: Date, nft: StateTypeNFT, wallet: StateType["wallet"]) => {
    if(!nft.core.redeemable) {
        return false;
    }
    else {
        // sale
        const redeemSale = nft.chainSale != null && nft.chainSale.ask == null && nft.chainSale.winner != null
            && wallet != null && nft.chainSale.winner.recipient == wallet.account;

        // auction
        const nftAuctionState = auctionState(now, nft)
        const accountIsWinnerOrLastBid = accountIsWinnerOrLastBidBool(nft, wallet)
        const redeemAuction = nftAuctionState == AUCTION_STATE_FINISHED && accountIsWinnerOrLastBid;

        return redeemAuction || redeemSale;
    }
}

export const auctionRemainingSec = (now: Date, nft: StateTypeNFT) => {
    if(nft.chainAuction==null || nft.chainAuction.bidHistory.length==0) return 0
    else {
        const firstBid = nft.chainAuction.bidHistory[0]
        return Math.max(
             0,
            ((firstBid.timestamp+nft.chainAuction.duration*SECONDS_MS) - now.getTime())/SECONDS_MS
        )
    }
}

export const nftAltOrTokenUri = (nft: StateTypeNFT) => (nft.offchainMeta && nft.offchainMeta.altTokenUri) || nft.chainCore?.tokenURI

// [I'm an inline-style link](https://www.google.com)
const reMarkdownLink = /\[(?<label>.+?)\]\((?<link>.+?)\)/g;

export const markdownToReact = (s: String) => {
    let idx = 0;

    return <React.Fragment>
        {Array.from(s.matchAll(reMarkdownLink)).map((m: RegExpMatchArray) => {
            return <React.Fragment>
                {m.index && m.index>idx && s.substring(idx, m.index)}
                {m.groups && <a href={m.groups['link']} target="_blank">{m.groups['label']}</a>}
                {(idx=(m.index||0) + m[0].length)==-1 && null}
            </React.Fragment>
        })}
        {s.length>idx && s.substring(idx)}
    </React.Fragment>
}
