
export const GLOBAL_SETNFT_INITIAL = "GLOBAL_SETNFT_INITIAL"

export const GLOBAL_SETNFT_CORE = "GLOBAL_SETNFT_CORE"
export const GLOBAL_SETNFT_SALE = "GLOBAL_SETNFT_SALE"
export const GLOBAL_SETNFT_AUCTION = "GLOBAL_SETNFT_AUCTION"

export const GLOBAL_SET_BIDS = "GLOBAL_SET_BIDS"

export const GLOBAL_MERGE_WALLET = "GLOBAL_MERGE_WALLET"

export const GLOBAL_REDEEM_SET = "GLOBAL_REDEEM_SET"
export const GLOBAL_REDEEM_INITIAL = "GLOBAL_REDEEM_INITIAL"

// buy states
/*
 not for sale
 connect wallet
 approve wETH
 buy NFT


 */
export const BUY_NONE = "BUY_NONE"
export const BUY_CONNECT = "BUY_CONNECT"
