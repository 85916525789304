import React from "react";
import {Link} from "react-router-dom";
import {StateTypeNFT} from "../redux/globalReducers";
import {
    AUCTION_STATE_FINISHED,
    AUCTION_STATE_LIVE,
    AUCTION_STATE_PRE,
    auctionState,
    nftAltOrTokenUri,
    priceToShow
} from "./common";
import {nftIsForSale, nftIsSold} from "../redux/nft";

/*
  Single NFT summary, quickview and link to detail page.
  Goes in Drop.tsx

 */

interface ParamTypes {
    dropId: string,
    nft: StateTypeNFT
}

const DropNFTQuickview = ({dropId, nft}: ParamTypes) => {

    const now = new Date()
    const nftAuctionState = auctionState(now, nft)

    return (
        <div className="col-xl-2 col-lg-3 col-md-4 col-6">
            <div className="product" data-aos="zoom-in" data-aos-delay="0">
                <div className="product-image mb-md-3">
                    { nftIsForSale(nft) && <div className="product-badge badge badge-secondary">Buy Now</div> }
                    { nftIsSold(nft) && <div className="product-badge badge badge-secondary">Sold</div> }
                    { (nftAuctionState==AUCTION_STATE_PRE || nftAuctionState==AUCTION_STATE_LIVE) && <div className="product-badge badge badge-secondary">Bid Now</div> }
                    { nftAuctionState==AUCTION_STATE_FINISHED && <div className="product-badge badge badge-secondary">Sold</div> }
                    <Link to={`/drop/${dropId}/nft/${nft.core.tokenId}`}>
                        <div className="product-swap-image"><img
                            className="img-fluid product-swap-image-front"
                            src={nftAltOrTokenUri(nft)}
                            alt=""/><img className="img-fluid"
                                                src={nftAltOrTokenUri(nft)}
                                                alt=""/></div>
                    </Link>
                    <div className="product-hover-overlay">
                        <Link to={`/drop/${dropId}/nft/${nft.core.tokenId}`} className="text-dark text-sm">
                            <svg className="svg-icon text-hover-primary svg-icon-heavy d-sm-none">
                                <use xlinkHref="#retail-bag-1"></use>
                            </svg>
                            <span className="d-none d-sm-inline">Detail</span>
                        </Link>
                        {/*<div><a className="text-dark text-hover-primary mr-2" href="#">*/}
                        {/*    <svg className="svg-icon svg-icon-heavy">*/}
                        {/*        <use xlinkHref="#heart-1"></use>*/}
                        {/*    </svg>*/}
                        {/*</a><a className="text-dark text-hover-primary text-decoration-none" href="#"*/}
                        {/*       data-toggle="modal" data-target="#quickView">*/}
                        {/*    <svg className="svg-icon svg-icon-heavy">*/}
                        {/*        <use xlinkHref="#expand-1"></use>*/}
                        {/*    </svg>*/}
                        {/*</a></div>*/}
                    </div>
                </div>
                <div className="position-relative">
                    <h3 className="text-base mb-1"><Link to={`/drop/${dropId}/nft/${nft.core.tokenId}`} className="text-dark">{nft.chainCore?.metadata.name}</Link></h3>
                    <span className="text-gray-500 text-sm">{priceToShow(nft)}</span>
                    {/*<div className="product-stars text-xs"><i className="fa fa-star text-primary"></i><i*/}
                    {/*    className="fa fa-star text-primary"></i><i*/}
                    {/*    className="fa fa-star text-primary"></i><i*/}
                    {/*    className="fa fa-star text-muted"></i><i className="fa fa-star text-muted"></i>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

export default DropNFTQuickview
