import axios from "axios";
import {globalSetNftInitial} from "../redux/globalActions";
import {AppDispatch} from "../redux/store";
import {StateType} from "../redux/globalReducers";

// server side json config
const srcNFTArtistsJson = '/nft/nftArtists.json'
const srcNFTDropsJson = process.env.REACT_APP_SCRAMBLE_NFTDROPS || '/nft/nftDropsEmpty.json' // empty fallback

export interface InitState {
    nft: StateType["nft"]
    drops: StateType["drops"]
    menu: StateType["menu"]
}

export const getSetInitialState = async (dispatch: AppDispatch) => {
    return getInitialState(dispatch)
        .then((initState: InitState) => {
            dispatch(globalSetNftInitial(initState.nft, initState.drops, initState.menu))
        })
        .catch(err => console.log(err))
}

export const getInitialState = async (dispatch: AppDispatch) => {
    return axios.get(srcNFTArtistsJson)
        .then(r => r.data)
        .then(artists => {
            return axios.get(srcNFTDropsJson)
                .then(r => r.data)
                .then(initialNFTs => {
                    let nft = {}
                    initialNFTs.nft.forEach((n: any) => {
                        n.offchainMeta.artist = artists[n.offchainMeta.artist]
                        // @ts-ignore
                        nft["" + n.core.tokenId] = n
                    })

                    let drops = {}
                    initialNFTs.drops.forEach((d: any) => {
                        // @ts-ignore
                        drops["" + d.name] = {
                            label: d.label,
                            displayOrder: d.displayOrder.map((i: number) => {
                                return "" + i;
                            }),
                            splash: d.splash,
                            about: d.about
                        }
                    })

                    let menu = initialNFTs.menu

                    const initState: InitState = {nft: nft, drops: drops, menu: menu}
                    return initState
                })
        }).then(initState => initState)
}
